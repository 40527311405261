<template>
  <div id="orders">
    <b-row class="mb-5">
      <b-col>
        <h1>{{ $t('Payments') }}</h1>
        <h5 class="text-primary">
          {{ $t('shopPay') }}
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          ref="refInvoiceListTable"
          :items="allWithdrawal"
          :busy="isBusy"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          hover
          :empty-text="$t('emptyText')"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>{{ $t('load') }}...</strong>
            </div>
          </template>
          <template #cell(order)="data">
            <div>
              <div
                v-if="!isEmpty(data.item.node.order)"
                class="d-flex flex-column"
              >
                <b
                  v-for="(item, index) in data.item.node.order.edges"
                  :key="index"
                >
                  {{ item.node.orderId }}
                </b>
              </div>
            </div>
          </template>
          <template #cell(payment_id)="data">
            <div>
              <!-- {{ data.item.node }} -->
              {{ data.item.node.paymentId }}
            </div>
          </template>
          <template #cell(vendor)="data">
            <div>
              {{ isEmpty(data.item.node.vendor) ? '' : data.item.node.vendor.vendorName }}
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              {{ status[data.item.node.status] }}
            </div>
          </template>
          <template #cell(reasonCommission)="data">
            <div>
              {{ data.item.node.reasonCommission }}
            </div>
          </template>
          <template #cell(commission)="data">
            <div>
              {{ format(data.item.node.commission) }}{{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(amount)="data">
            <div>
              {{ format(data.item.node.amount) }}{{ currencyType[currency] }}
            </div>
          </template>
          <template #cell(withdrawal_creator)="data">
            <div>
              {{ isEmpty(data.item.node.withdrawalCreator) ? '' :
                cleanUsername(data.item.node.withdrawalCreator.username)
              }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div>
              {{ fechaFormato(data.item.node.createdAt) }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex ">
        <b-pagination
          v-if="rows > perPage"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          size="lg"
          @input="fetchData"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BSpinner,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, isEmpty, cleanUsername, utf8ToB64, getVendorProfileData,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BTable,
    BPagination,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: getUserData(),
      orders: null,
      isBusy: false,
      allWithdrawal: [],
      vendor: null,
      allVendors: [],
      filterDate: '',
      currency: null,
      myId: null,
      isEmpty,
      tableColumns: [
        { key: 'order', label: this.$t('Orders') },
        { key: 'payment_id', label: this.$t('idPaid') },
        { key: 'vendor', label: this.$t('Vendedor') },
        { key: 'status', label: this.$t('editContent.status') },
        { key: 'reasonCommission', label: this.$t('reasonCommision') },
        { key: 'commission', label: this.$t('commisionPaid') },
        { key: 'amount', label: this.$t('totalPayment') },
        { key: 'withdrawal_creator', label: this.$t('creatorPayment') },
        { key: 'created_at', label: this.$t('subscriptions.createdAt') },

      ],

      currencyType: {
        EUR: '€',
        USD: '$',
        GBP: '£',
        ARS: 'Argentine peso',
        isInternal: false,
      },
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      status: {
        PEN: this.$t('pending'),
        COM: this.$t('completed'),
        CAN: this.$t('cancelled'),
      },
      rows: 0,
      perPage: 20,
      currentPage: 1,
    }
  },

  watch: {
    filterDate() {
      this.fetchData()
    },
    vendor() {
      this.fetchData()
    },
  },

  async mounted() {
    this.getPayMethod()
    if (this.userData.profile.isVendor) {
      this.tableColumns = this.tableColumns.filter(item => item.key !== 'vendor')
      await getVendorProfileData(this.userData.profile.id).then(result => {
        if (result) {
          this.vendor = result.id
        }
      })
    } else {
      this.fetchData()
    }
  },
  methods: {
    format(num) {
      const reg = /\d{1,3}(?=(\d{3})+$)/g
      return (`${num}`).replace(reg, '$&.')
    },

    getElement(id = null) {
      if (id != null) {
        this.$router.push({ name: 'subOrders', params: { idFinalOrder: id } })
      } else {
        this.$router.push({ name: 'orders' })
      }
    },

    clickRow(data) {
      const { id } = data[0].node
      this.myId = id
      if (id != null) {
        this.$router.push({ name: 'subOrders', params: { idFinalOrder: id } })
      } else {
        this.$router.push({ name: 'orders' })
      }
    },

    cleanUsername,
    fetchData() {
      this.isBusy = !this.isBusy
      const query = `
                {
                    allWithdrawal(first:${this.perPage},
                            ${this.vendor != null ? `vendor:"${utf8ToB64(`id:${this.vendor}`)}"` : ''}
                            offset:${this.perPage * (this.currentPage - 1)},
                            ${this.filterDate !== '' ? `createdAt:"${this.filterDate}",` : ''}
                            client: "${this.userData.profile.client.id}") {
                        edges {
                        node {
                            id
                            order {
                                edges {
                                    node {
                                    id
                                    orderId
                                    }
                                }
                            }
                            paymentId
                            vendor {
                            id
                            vendorName
                            }
                            status
                            paymentGateway        
                            amount
                            commission
                            reasonCommission
                            withdrawalCreator {
                            id
                            username
                            }
                            createdAt
                            
                        }
                        }
                    }                
                }
                `
      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)

          this.allWithdrawal = result.data.data.allWithdrawal.edges
          this.rows = result.data.data.allWithdrawal.totalCount
          this.isBusy = !this.isBusy
        })
        .catch(err => { console.log(err) })
    },

    getVendor() {
      const query = `{
                allVendors(client:"${this.userData.profile.client.id}",order:true) {
                    totalCount
                    edges {
                        node {
                            id
                            name
                            vendorName
                            
                            cartVendor {
                            edges {
                            node {
                                id
                                orderId
                                
                            }
                            }
                        }                    
                        client {
                            id
                            name
                        }
                        }
                    }
                    }
                }`

      axios
        .post('', { query }).then(result => {
          messageError(result, this)

          this.allVendors = result.data.data.allVendors.edges
        }).catch(err => {
          console.log(err)
        })
    },
    clickPickr() {
      document.getElementById('pickr').click()
    },

    getPayMethod() {
      axios
        .post('', {
          query: `
                  query{
                    client(id:"${this.userData.profile.client.id}"){
                      externalPayMethod
                      currency
                    }
                }
            `,
        })
        .then(res => {
          messageError(res, this)
          this.currency = res.data.data.client.currency
        })
        .catch(() => {
        })
    },
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
    errorImg(e) {
      e.target.src = fileError
    },
    buildImageUrl(folder) {
      return folder.imageVendorUrl == null || folder.imageVendorUrl.length === 0
        ? noCover
        : folder.imageVendorUrl
    },
  },

}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#orders .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#orders .cardContent img {
  height: 11vw;
  object-fit: cover;
  /*width: 100%;
            height:  100%;
            object-fit: cover;*/
}

#orders .card-body {
  padding: 1rem;
}

#orders .card-body h4 {
  font-size: 1rem !important;
}

#orders .card-header {
  padding: 1rem;
}

#orders .card-header h4 {
  font-size: 1rem !important;
}

#orders .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;

  justify-content: space-between;
}

#orders #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#orders #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}
</style>
